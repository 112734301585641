@import "../../styles/mixins/utilities-rem";

$list-item-width: 335;

.container {
  max-width: rem($list-item-width);
}

.item {
  max-width: rem($list-item-width);
}

@media (min-width: rem(687)) {
  .container {
    max-width: rem($list-item-width * 2);
  }

  .title {
    padding: 0 rem(4);
  }
}

@media (min-width: rem(703)) {
  .list {
    justify-content: flex-start;
  }
}

@media (min-width: rem(1022)) {
  .container {
    max-width: rem($list-item-width * 3);
  }
}

@media (min-width: rem(1357)) {
  .container {
    max-width: rem($list-item-width * 4);
  }
}
