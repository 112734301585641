@import "../../styles/mixins/utilities-rem";

.animationTicker {
  animation-name: ticker;
  animation-duration: 300s;
  animation-delay: -150s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  left: 100%;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
    visibility: visible;
  }

  100% {
    transform: translateX(-100%);
  }
}
