@import "../../styles/mixins/utilities-rem";

.overlay {
  svg {
    position: absolute;
    top: rem(1);
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.overlayDesktop {
  svg {
    top: 0;
  }
}
