@use "sass:math";

$decoration-width: percentage(math.div(1, 6));

.background {
  top: 0;

  svg {
    height: 100%;
  }

  &--even {
    left: 0;
    justify-content: flex-end;
    margin-left: $decoration-width;
  }

  &--odd {
    right: 0;
    justify-content: flex-start;
    margin-right: $decoration-width;
  }
}
