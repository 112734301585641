@import "../../styles/mixins/utilities-rem";
          
.waveTop, .waveBottom {
  svg {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.waveTop {
  svg {
    top: rem(2);
  }
}

.waveBottom {
  svg {
    top: rem(-2);
  }
}
