@import "../styles/mixins/utilities-rem";

.showMore {
  svg {
    margin-left: rem(5);
    margin-top: rem(-2);
    height: rem(30);
    width: rem(30);
  }
}

.waveBottom {
  svg {
    position: absolute;
    left: 0;
    top: rem(1);
    width: 100%;
    height: 100%;
  }
}
